<template>
  <div>
    <el-breadcrumb class="Bdh" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>拍卖监控</el-breadcrumb-item>
      <el-breadcrumb-item>分组列表</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- <div class="add">
      <el-button @click="getdata()">刷新</el-button>
    </div> -->
    <div style="text-align:left;">
      <p style="margin:0;">房间数：{{roomnum}}</p>
      <p style="margin:0;">总人数：{{peoplenum}}</p>
    </div>
    <el-table :data="list" border style="width: 100%">
      <el-table-column label="ID" prop="id">
        <template slot-scope="scope">
          <span>{{scope.row.auction.id}}</span>
        </template>
      </el-table-column>
      <el-table-column label="房间号" prop="roomNO">
        <template slot-scope="scope">
          <span>{{scope.row.auction.roomNO}}</span>
        </template>
      </el-table-column>
      <el-table-column label="时间" prop="startTime">
        <template slot-scope="scope">
          <p style="font-size:10px;">开始时间：{{scope.row.auction.startTime}}</p>
          <p style="font-size:10px;">结束时间：{{scope.row.auction.endTime}}</p>
        </template>
      </el-table-column>
      <!-- <el-table-column label="结束时间" prop="endTime">
        <template slot-scope="scope">
          <span>{{scope.row.auction.endTime}}</span>
        </template>
      </el-table-column> -->
      <el-table-column label="是否上架" prop="isOnline" sortable>
        <template slot-scope="scope">
          <div v-html="filteronline(scope.row.auction.isOnline)"></div>
        </template>
      </el-table-column>
      <el-table-column label="产品名" prop="name">
        <template slot-scope="scope">
          <span>{{scope.row.goods.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="产品图" prop="thumb">
        <template slot-scope="scope">
          <img slot="reference" :src="scope.row.goods.thumb" style="width: 120px;margin-left:20px;" />
        </template>
      </el-table-column>
      <el-table-column label="人数" prop="count">
        <template slot-scope="scope">
          <span style="font-size:16px;color:#409EFF;font-weight:bold;">{{scope.row.count}}</span>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
export default {
  data() {
    return {
      list: [],
      roomnum: 0,
      peoplenum: 0,
    };
  },
  mounted() {
    setInterval(this.getdata, 3000);
  },
  methods: {
    filteronline(val) {
      if (val == 0) {
        return "<span style='color:#F56C6C;'>未上架</span>";
      } else {
        return "<span style='color:#67C23A;'>已上架</span>";
      }
    },
    getdata() {
      this.roomnum = 0;
      this.peoplenum = 0;
      this.axios
        .get(
          this.serveurl.mallserve + "/api/admin/Auction/get_all_auction_group"
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.list = res.data.data;
            this.roomnum = this.list.length;
            for (let a = 0; a < this.list.length; a++) {
              this.peoplenum = this.peoplenum + Number(this.list[a].count);
            }
          } else {
            this.list = [];
          }
        });
    },
  },
};
</script>
<style>
.Bdh {
  height: 50px;
  width: 100%;
  line-height: 40px;
}
.add {
  width: 100%;
  height: 50px;
  text-align: left;
}
</style>